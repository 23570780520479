<template>
  <div class="inside-page-content">
    <div class="paper all-center">
      <div class="top-row">
          <div class="top-name">{{detailData.title}}</div>
          <div class="condition">
            <label>【时间】：{{detailData.time}}</label>
          </div>
      </div>
      <!--分割线-->
      <div class="divider"></div>

      <div v-text="detailData.content" class="detail-content">

      </div>
    </div>
  </div>
</template>

<script>
import TopFilter from './components/topFilter.vue'
export default {
  name: 'policyDetail',
  components: {
    TopFilter
  },
  data() {
    return {
      id:null,
      detailData: {},
    }
  },
  computed:{
  },
  created(options) {
    this.id = this.$route.query?.id
    this.getDetail()
  },
  mounted() {

  },
  methods: {
    getDetail() {
      this.$api.technologyFinance.policyDetail(this.id).then((res) => {
        this.detailData = res.data.data
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

.top-row {
  height: 130px;
  padding: 0 80px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  .top-name{
    font-weight: 700;
  }
  .condition{
    font-size: 12px;
    display: flex;
    flex-direction: column;
  }

}
.divider{
  width: 95%;
  margin: 20px auto;
  border-bottom: 1px dashed grey;
}
.detail-content{
  margin: auto;
  width: fit-content;
  white-space:pre-wrap;

}

</style>
